import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './subscription-plan.module.scss';
import {
  FreeSubscription,
  BasicSubscription,
  AdvancedSubscription,
  PremiumSubscription,
  SubscriptionPlanMobile,
  SubscriptionPlanWeb,
} from '../../../../assets/images';
import FreeTrial from '../../../../assets/images/FreeTrialBg.png';
import SubscriptionBg from '../../../../assets/images/SubscriptionBg.png';
import FreeIcon from '../../../../assets/images/FreeSubIcon.png';
import PremiumIcon from '../../../../assets/images/PremiumSubIcon.png';
import { UserService } from '../../../../common/services/user/user.service';
import useWindowDimensions from '../../../../common/hooks/useWindowDimensions';
import { setRedirectURI } from '../../../../store/common/common.reducer';
import ENVIRONMENTS from '../../../../environments';

const userServices = new UserService();

const SubscriptionCard = ({ item }: any) => {
  return (
    <Grid
      item
      lg={3}
      md={6}
      sm={6}
      xs={12}
      className={styles.cardContainerWrapper}
    >
      <div className={styles.cardContainer1}>
        <div className={styles.cardHeader1}>
          <img className={styles.icon} src={item.icon} alt="Img" />
          <div>
            <h5>{item?.title}</h5>
            {(item?.android && item?.ios) || item?.isFree ? (
              <>
                <span className={styles.sub_title}>
                  Android:{' '}
                  {item?.isFree ? (
                    <>Free</>
                  ) : (
                    <>
                      {item?.android}
                      {/* {item?.android?.symbol}
                      {item?.android?.values ?? item?.android?.value}/
                      {item?.android?.cycle} */}
                    </>
                  )}
                </span>
                <br />
                <span className={styles.sub_title}>
                  iPhone:{' '}
                  {item.isFree ? (
                    <>Free</>
                  ) : (
                    <>
                      {item?.ios}
                      {/* {item?.ios?.symbol}
                      {item?.ios?.values ?? item?.ios?.value}/{item?.ios?.cycle} */}
                    </>
                  )}
                </span>
              </>
            ) : (
              <div className={styles.to_be_announced}>To be announced</div>
            )}
          </div>
        </div>
        <div className={styles.contents}>
          {item.content.map((cont: string) => (
            <p className={styles.description_text}>- {cont}</p>
          ))}
        </div>
      </div>
    </Grid>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SubscriptionPlan = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { search } = location;
  const params = useMemo(() => new URLSearchParams(search), []);
  const { width } = useWindowDimensions();

  // const [subscriptions, setSubscriptions] = useState([]) as any;

  // const getSubcriptionPlans = async () => {
  //   try {
  //     const response = await userServices.getSubscriptionPlans();

  //     let obj = {} as any;
  //     console.log("subscriptions", response.data);
  //     // eslint-disable-next-line array-callback-return
  //     response?.data?.map((x: any) => {
  //       obj = {
  //         ...obj,
  //         [x.name.toUpperCase()]: {
  //           ...obj[x.name.toUpperCase()],
  //           [x.platformType.toUpperCase()]: {
  //             ...x.price,
  //             cycle: x.cycle === 'monthly' ? 'Month' : x.cycle,
  //           },
  //         },
  //       };
  //     });

  //     setSubscriptions(obj);
  //   } catch (err) {
  //     // eslint-disable-next-line no-empty
  //   }
  // };

  // useEffect(() => {
  //   getSubcriptionPlans();
  // }, []);

  // const { PREMIUM } = subscriptions;

  const plans = [
    // {
    //   title: 'Basic',
    //   icon: BasicSubscription,
    //   ios: BASIC?.IOS,
    //   android: BASIC?.ANDROID,
    //   isFree: false,
    //   content: [
    //     'QuickCam Camera Mode',
    //     'Recording 1X to 3X fast motion',
    //     'Recording 1X to -3X slow motion',
    //     'Quick Editor',
    //     'Mobile Dashboard',
    //     // 'Business Dashboard • Back Office',
    //   ],
    // },

    // {
    //   title: 'Advance Plan',
    //   icon: AdvancedSubscription,
    //   ios: ADVANCED?.IOS,
    //   android: ADVANCED?.ANDROID,
    //   isFree: false,
    //   content: [
    //     'QuickCam Camera Mode',
    //     'Recording 1X to 4X fast motion',
    //     'Recording 1X to -4X slow motion',
    //     'Quick Editor',
    //     'Mobile Dashboard',
    //     // 'Business Dashboard • Back Office',
    //   ],
    // },
    {
      title: 'Subscription Plan',
      icon: PremiumIcon,
      // ios: PREMIUM?.IOS,
      // android: PREMIUM?.ANDROID,
      ios: '$4.99/Month',
      android: '$4.99/Month',
      isFree: false,
      content: [
        'QuickCam Camera Mode',
        'Recording 1X to 3X fast motion',
        'Recording 1X to -3X slow motion',
        'Quick Editor',
        'Social Sharing',
        '1500 Confirmed QuickCam Coins',
        'Mobile Dashboard',
      ],
    },
  ];

  const redirectToSignUp = () => {
    const redirectUri =
      window.location.origin +
      (location.pathname === '/' ? '' : location.pathname);
    if (location.pathname !== '/')
      window.localStorage.setItem('redirect_uri', redirectUri);
    dispatch(
      setRedirectURI({
        url: window.location.href,
        refCode: params.get('refCode') || '',
      }),
    );
    const signUphURL = `${ENVIRONMENTS.KEYCLOAK_ACCOUNT}/protocol/openid-connect/registrations?client_id=${ENVIRONMENTS.KEYCLOAK_CLIENT_ID}&response_mode=fragment&response_type=code&login=true&redirect_uri=${redirectUri}&from=Signup`;
    window.location.href = signUphURL;
  };

  return (
    <>
      <section className={styles.bannerContainer}>
        <div className={styles.webSubContainer}>
          <img src={FreeTrial} alt="subscriptionPlan" />
          <div className={styles.content}>
            <div className={styles.contentInner}>
              <h2>Join today!</h2>
              <h6>
                Click on Get Started to be part of this growing movement! 🎉
              </h6>
              <button type="button" onClick={redirectToSignUp}>
                Get Started
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubscriptionPlan;
